import React, { useEffect, useState, memo, FC, useRef } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import GlobalPageWrapper from '../../../../../components/Global/GlobalPagewrapper';
import useDebounce from '../../../../../hooks/useDebounceHook';
import moment from 'moment';
import { CrownIcon } from '../../../../../images/svgs';
import {
  formatDateTime,
  formatDateToString,
} from '../../../../../utils/helper';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import {
  IRewardHistory,
  TokenType,
} from '../../../../../types/countryPool.type';
import GlobalSelect, {
  IGlobalSelect,
} from '../../../../../components/Global/GlobalSelect';
import PlatformComingSoon from '../../../../../components/ExternApps/PlatformComingSoon';
import { FEATURES } from '../../../../../utils/constants';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../../utils/utils.ts';
import { useSelector } from 'react-redux';
import { selectRewardSlice } from '../../../../../store/Cloudk2/rewardCalculatorSlice.ts';

type PoolRewardHistoryProps = {
  data: IRewardHistory;
  typeList: TokenType[] | null;
};

const PoolRewardHistory: FC<PoolRewardHistoryProps> = memo(
  ({ data, typeList }) => {
    const { LYK } = useSelector(selectRewardSlice);

    const [rewardHistory, setRewardHistory] = useState(data);
    const [typeListData, setTypeListData] = useState(typeList);

    const { getRewardHistory } = useCountryPoolActions();
    const [shouldFetchData, setShouldFetchData] = useState(true);

    const [resetFilter, setResetFilter] = useState(false);
    const [dateChanged, setDateChanged] = useState<boolean>(false);
    const [resetDisable, setResetDisable] = useState(
      !rewardHistory?.list?.length
    );
    const [isLoading, setLoading] = useState({
      searchLoading: false,
      dataFilter: false,
    });

    const [filterRewardsValue, setFilterRewardValue] = React.useState<any>({
      page: 1,
      limit: 10,
      fromDate: null,
      toDate: null,
      status: null,
    });

    const [query, setQuery] = useState('');

    const emptyCheck = () =>
      !filterRewardsValue.query &&
      !filterRewardsValue.fromDate &&
      !filterRewardsValue.toDate &&
      !filterRewardsValue.status &&
      !query &&
      !dateChanged;

    const {
      debouncedValue: debouncedSearchValue,
      setDebouncedValue: setSearchDebounce,
    } = useDebounce(query, 1000);

    const prevFilterActiveValue = useRef(filterRewardsValue);
    const prevDebouncedSearchValue = useRef(debouncedSearchValue);

    useEffect(() => {
      if (
        prevFilterActiveValue.current !== filterRewardsValue ||
        prevDebouncedSearchValue.current !== debouncedSearchValue
      ) {
        const fetchData = async () => {
          setResetFilter(false);
          setLoading({ ...isLoading, dataFilter: true });

          await getRewardHistory({
            ...filterRewardsValue,
            query: debouncedSearchValue,
          }).then((response) => {
            setRewardHistory(response?.data?.data);
            setLoading({
              searchLoading: false,
              dataFilter: false,
            });
          });
        };
        prevFilterActiveValue.current = filterRewardsValue;
        prevDebouncedSearchValue.current = debouncedSearchValue;
        fetchData();
      }
    }, [filterRewardsValue, debouncedSearchValue]);
    useEffect(() => {
      setResetFilter(false);
    }, [filterRewardsValue.fromDate, filterRewardsValue.toDate, dateChanged]);
    const rows = rewardHistory?.list?.map((ele) => {
      return {
        poolName: ele?.poolName,
        poolTokens: ele?.totalPoints,
        rewards: (
          <div className="flex items-center gap-1">
            {Number(formatNumberWithCommas(ele?.userReward)).toFixed(2) || 0}{' '}
            LYK-W
          </div>
        ),
        value: (
          <div className="flex items-center gap-1">
            ${' '}
            {toFixedWithoutRoundingOff(Number(ele?.userRewardAmountUSD), 2) ||
              0}
          </div>
        ),
        status: (
          <p>
            {' '}
            {`${ele?.claimStatus?.charAt(0).toUpperCase()}${ele?.claimStatus?.slice(1).toLowerCase()}`}
          </p>
        ),
        date: (
          <span>{ele?.createdAt ? formatDateTime(ele?.createdAt) : '-'}</span>
        ),
      };
    });

    const handleReset = () => {
      setResetFilter(true);
      setDateChanged(false);
      setFilterRewardValue({
        page: 1,
        limit: 10,
        fromDate: null,
        toDate: null,
        status: '',
      });
      setQuery('');
      setSearchDebounce('');
    };

    const headCells = [
      {
        id: 'poolName',
        label: 'Pool Name',
        dataClass: '!text-primaryGrey  !font-normal !pl-6 ',
      },
      {
        id: 'poolTokens',
        label: 'Pool Points',
        dataClass: '!text-primaryGrey  !font-normal  ',
      },
      {
        id: 'rewards',
        label: 'Rewards',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      {
        id: 'value',
        label: 'Value',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      {
        id: 'status',
        label: 'Status',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      {
        id: 'date',
        label: 'Date',
        dataClass: '!text-primaryGrey  !font-normal',
      },
    ];
    const handleDateDate = (startDate: any, endDate: any) => {
      const from = moment(startDate).format('YYYY-MM-DD');
      const to = moment(endDate).format('YYYY-MM-DD');
      setFilterRewardValue({
        ...filterRewardsValue,
        ...{ fromDate: from, toDate: to },
      });
    };

    if (rewardHistory?.list && rewardHistory?.list?.length && resetDisable)
      setResetDisable(false);
    const tokenType: IGlobalSelect[] =
      typeListData?.map((type, index) => {
        return {
          name: type?.symbol,
          value: type?.symbol,
        };
      }) || [];

    const statusOption = [
      {
        name: 'Claimed',
        value: 'claimed',
      },
      {
        name: 'Un Claimed',
        value: 'un-claimed',
      },
    ];

    const tableDropdowns = (
      <div className="flex flex-row space-x-3 sm:items-center">
        <GlobalSelect
          name="status"
          placeholder="Status"
          isError={false}
          value={filterRewardsValue.status}
          getSelectedValue={(item: string) => {
            setFilterRewardValue((prev: any) => ({
              ...prev,
              status: item,
            }));
            // setShouldFetchData(true);
          }}
          options={statusOption}
          className={` !rounded-md !min-w-24 ${filterRewardsValue.claimStatus ? '!w-max ' : '!w-[135px]'} `}
          classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
          optionsWidth="w-max"
        />
        <button
          className={`${emptyCheck() ? 'text-gray-400' : 'text-primary'} h-10 text-xs font-medium `}
          type="button"
          onClick={handleReset}
          disabled={emptyCheck()}
        >
          Reset
        </button>
      </div>
    );

    useEffect(() => {
      setRewardHistory(data);
    }, [data]);

    useEffect(() => {
      setTypeListData(typeList);
    }, [typeList]);

    return (
      <GlobalPageWrapper containerClass="!p-0 !border-none !bg-transparent relative !rounded-lg ">
        <p className="mb-3 text-lg font-semibold ">Your Pool Reward History</p>
        <div className={`${!FEATURES.hideCountryPoolComingSoon && 'blur-md'}`}>
          <GlobalTable
            stopHoverEffect={true}
            totalPages={1}
            isLoading={isLoading?.dataFilter}
            dateArrowAllow={true}
            fromDateClassName={`py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
            toDateClassName={`py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
            containerStyles="!border-gray rounded-lg overflow-hidden"
            paginationPosition="right"
            rows={rows?.length ? rows : []}
            headCells={headCells}
            showTotalRecords={false}
            searchIsLoading={isLoading?.searchLoading}
            searchFieldPlaceholderText="Search"
            handlePageChange={(page) => {
              setFilterRewardValue({ ...filterRewardsValue, ...{ page } });
            }}
            setSearchValue={(e: any) => {
              setQuery(e);
            }}
            tableWidth="true"
            resetDateFilter={resetFilter}
            tableDropdowns={tableDropdowns}
            handleDateDate={handleDateDate}
            searchValue={query}
            isCellClass={false}
            bodyClass={'!min-h-0 !rounded-lg'}
            bodyStyles="!text-black !text-normal  !font-normal"
            setDateChanged={setDateChanged}
          ></GlobalTable>
        </div>

        {!FEATURES.hideCountryPoolComingSoon && <PlatformComingSoon />}
      </GlobalPageWrapper>
    );
  }
);

export default PoolRewardHistory;
