import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../images/svgs';
import { selectRewardSlice } from '../../../../../store/Cloudk2/rewardCalculatorSlice';
import { useSelector } from 'react-redux';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../../../../../utils/utils';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';

const BannerComp = ({
  data,
  loading,
  claimLoading,
  rewardToken,
}: {
  data: any;
  loading: boolean;
  claimLoading: boolean;
  rewardToken: String;
}) => {
  const { LYK } = useSelector(selectRewardSlice);

  const [totalSngpPointsSum, setTotalSngpPointsSum] = useState(0);
  const [totalSngpPointsUSDSum, setTotalSngpPointsUSDSum] = useState(0);
  const { claimCountryPoolReward } = useCountryPoolActions();

  useEffect(() => {
    if (data?.length) {
      const sum = data.reduce(
        (acc: any, item: any) => acc + item.totalPoints,
        0
      );
      const sumUSD = data.reduce(
        (acc: any, item: any) => acc + item.totalPointsUSD,
        0
      );
      setTotalSngpPointsSum(sum);
      setTotalSngpPointsUSDSum(sumUSD);
    }
  }, [data]);

  return (
    <div className="flex lg:min-h-60 h-auto w-full justify-between xxs:p-3 md:p-6 bg-countryBg bg-cover bg-no-repeat bg-center">
      {loading ? (
        <div className="flex justify-center items-center flex-1 h-full min-h-32">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex xxs:flex-col lg:flex-row xxs:gap-2 md:gap-1 w-full">
          <Stack
            direction={{ xs: 'column' }}
            // spacing={{ xs: 0.5, md: 1 }}
            width={{ xs: '100%', md: '100%' }}
            padding={'4px'}
            border={'4px'}
            className="flex-1 lg:justify-between xxs:gap-"
            // className="border-4 rounded-lg p-2 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500"
          >
            <span className="text-white font-medium md:text-3xl text-2xl ">
              Total Country Pool Rewards
            </span>
            <span className="text-white font-bold md:text-4xl text-2xl">
              {Number(formatNumberWithCommas(totalSngpPointsSum)).toFixed(6)}{' '}
              {rewardToken}
            </span>
            <span className="text-white font-semibold md:text-3xl text-xl">
              $
              {formatNumberWithCommas(
                Number(toFixedWithoutRoundingOff(totalSngpPointsUSDSum, 2)) || 0
              )}
            </span>

            {claimLoading ? (
              <Spinner className="animate-spin h-6" />
            ) : (
              <button
                onClick={claimCountryPoolReward}
                className="px-6 py-2 w-36 h-9 flex justify-center items-center rounded-lg bg-white text-[#1B9BD6] font-normal border border-[#1B9BD6] hover:bg-[#1B9BD6] hover:text-white transition-all shadow-sm"
              >
                Claim Now
              </button>
            )}
          </Stack>
          {
            <div className="flex-1">
              {/* // data.length ?  */}
              <div className="w-full bg-countryBorderGredient p-[2px] rounded-tl-[10px] rounded-br-[10px] rounded-tr-[0px] rounded-bl-[0px]">
                <div className="flex flex-col justify-between xxs:p-2 md:p-4 gap-1 bg-[#1f6fb2] w-full h-full flex-1 rounded-tl-[10px] rounded-br-[10px] rounded-tr-[0px] rounded-bl-[0px]">
                  {data?.map((item: any) => {
                    return (
                      <div className="flex h-11 justify-between xxs:p-1 md:p-3 gap-1 items-center w-full border border-[#FFFFFF]  bg-[#1B9FDA66] rounded">
                        <span className="font-semibold text-white xxs:text-sm md:text-lg">
                          {item?.poolName}
                        </span>
                        <span className="text-white font-semibold xxs:text-sm md:text-2xl">
                          {formatNumberWithCommas(item?.totalSngpPoints)}
                          {/* <span className="font-semibold md:text-base text-xs">
                            {' '}
                            {rewardToken}
                          </span> */}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            // : <></>
          }
        </div>
      )}
    </div>
  );
};

export default BannerComp;
